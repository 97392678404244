@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?uq24qt');
  src:  url('fonts/icomoon.eot?uq24qt#iefix') format('embedded-opentype'),
    url('fonts/icomoon.woff2?uq24qt') format('woff2'),
    url('fonts/icomoon.ttf?uq24qt') format('truetype'),
    url('fonts/icomoon.woff?uq24qt') format('woff'),
    url('fonts/icomoon.svg?uq24qt#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="netx-"], [class*=" netx-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.netx-cup:before {
  content: "\e915";
  color: #1d4030;
}
.netx-minus:before {
  content: "\e916";
  color: #1f4836;
}
.netx-plus:before {
  content: "\e917";
  color: #1f4836;
}
.netx-star:before {
  content: "\e914";
  color: #3f5947;
}
.netx-notification:before {
  content: "\e913";
  color: #1f4836;
}
.netx-change:before {
  content: "\e912";
  color: #1f4836;
}
.netx-user:before {
  content: "\e90d";
  color: #1f4836;
}
.netx-arrow:before {
  content: "\e900";
  color: #1f4836;
}
.netx-checkbox:before {
  content: "\e901";
  color: #1f4836;
}
.netx-closed-eye:before {
  content: "\e902";
  color: #1f4836;
}
.netx-cross:before {
  content: "\e903";
  color: #1f4836;
}
.netx-facebook:before {
  content: "\e904";
  color: #1f4836;
}
.netx-heart-filled:before {
  content: "\e905";
  color: #1f4836;
}
.netx-heart:before {
  content: "\e906";
  color: #1f4836;
}
.netx-language:before {
  content: "\e907";
  color: #1f4836;
}
.netx-link:before {
  content: "\e908";
  color: #1f4836;
}
.netx-linkedin:before {
  content: "\e909";
  color: #1f4836;
}
.netx-location:before {
  content: "\e90a";
  color: #1f4836;
}
.netx-membership:before {
  content: "\e90b";
  color: #1f4836;
}
.netx-account:before {
  content: "\e90c";
  color: #1f4836;
}
.netx-menu:before {
  content: "\e90e";
  color: #1f4836;
}
.netx-opened-eye:before {
  content: "\e90f";
  color: #1f4836;
}
.netx-twitter:before {
  content: "\e910";
  color: #1f4836;
}
.netx-upload:before {
  content: "\e911";
  color: #1f4836;
}
